//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Develop greater awareness for your practice',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      "Keep your practice top-of-mind with current and potential patients with visual content that's easy to produce",
    descriptionColor: 'white-3',
    image: 'industryHealthcare',
    iconShow: true,
    iconContext: 'health',
    // iconColor: 'sky',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'cirrus',
    expandcol: 5,
  },
]
const featureData = {
  title: 'Drive awareness of your practice on social media',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Offers and promotions',
          titlecolor: 'sky-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'sky-1',
          iconname: 'tag',
          iconcolor: 'sky',
          path: '/platform/offers-promotions/',
          description:
            'Drive visits and new patients by creating image-rich offers and promotions for social media',
        },

        {
          title: 'Content marketing',
          titlecolor: 'green-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'green-1',
          iconname: 'lightbulb',
          iconcolor: 'green',
          path: '/platform/pulse-surveys/',
          description:
            'Engage your patients with visual tips & tricks, pointers, and other content - all easily customized via templates',
        },
        {
          title: 'Feedback and reviews',
          titlecolor: 'bluegreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'bluegreen-1',
          iconname: 'megaphone',
          iconcolor: 'bluegreen',
          path: '/platform/review-management/',
          description:
            'Ask your patients to review your practice by customizing visual templates that drive action',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Health Care - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive awareness with current and new patients for your medical or dental practice',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium purple-1">
            Drive awareness and patient visits for your office
          </h1>
          <p class="display-4">
            Engage patients with visual content that drives them &mdash; and
            their referrals &mdash; to your office
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
